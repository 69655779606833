import * as React from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { StaticImage } from "gatsby-plugin-image"

import sdg_1 from "../../images/sdgsicon/sdg_1.png";


import {
  PostTitle,
} from './post-card.style';




interface PostCardProps {
  image?: any;
  title: string;
  description?: string;
  url: string;
  sdgs_no: string;
  no: string;
  height_name: string;
  color_name: string;
  date?: string;
  tags?: [];
  className?: string;
  imageType?: 'fixed' | 'fluid';
}

const PostCard: React.FunctionComponent<PostCardProps> = ({
  image,
  title,
  description,
  url,
  date,
  tags,
  className,
  imageType,
  sdgs_no,
  title_height_name,
  title_color_name,
  height_name,
  color_name,
  no,
  key,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = ['post_card'];

  // className prop checking
  if (className) {
    addAllClasses.push(className);
  }




  return (
  <div key={key}>
    <div className="post_details">
      <div>
        <div style={{display:"flex"}}>
          <div>
            <a href={"/tags/sdgs-"+sdgs_no}>

{/* 画像表示調整中 */}
{/* <StaticImage src={"../../images/sdgsicon/sdg_"+"2.png"} alt="img" width={200} style={{width:"5vw", margin:"2px"}}/> */}

              <img style={{width:"5vw",margin:"2px"}} src={"/sdgsicon/sdg_"+sdgs_no+".png"}/>
            </a>
          </div>
          <div>
            <PostTitle style={{margin:"0 0 0 0"}}>
              <Link style={{margin:"0 0 0 0"}} to={url+"a/#t"}>　{no}　{title}
              <span style={{fontSize:"small"}}>
              　（
            {title_height_name} × {title_color_name}
              ）
              </span>
              </Link>
            </PostTitle>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

PostCard.defaultProps = {
  imageType: 'fluid',
};

export default PostCard;
